import React, { useMemo } from "react";
import { useSelector } from "react-redux";

function ChampionTable({
  champions,
  teamName,
  reRoll,
  table,
  tableOptions,
  sortOption,
}) {
  const championImages = useSelector((state) => state.championImages);

  const sortedChampions = useMemo(() => {
    const championsCopy = [...champions];

    const getSortedChampions = () => {
      switch (sortOption) {
        case "alphabetical":
          return championsCopy.sort((a, b) => a.name.localeCompare(b.name));
        default:
          return champions;
      }
    };

    return getSortedChampions();
  }, [champions, sortOption]);

  if (!champions || champions.length === 0) {
    return null;
  }

  return (
    <table
      className={`${!tableOptions.rank ? "hide-rank" : ""} ${
        !tableOptions.winrate ? "hide-winrate" : ""
      } ${!tableOptions.tier ? "hide-tier" : ""}`}
    >
      <thead>
        <tr>
          <th
            className={`${teamName === "블루 팀" ? "blue-team" : "red-team"}`}
            colSpan="4"
          >
            {teamName}
          </th>
        </tr>
        <tr>
          <th className="champion">챔피언</th>
        </tr>
      </thead>
      <tbody>
        {sortedChampions.map((champion) => {
          if (!champion || !champion.id) return null; // 유효성 검사 추가
          const originalIndex = champions.findIndex(
            (ch) => ch && ch.id === champion.id
          );

          return (
            <tr key={champion.id} onClick={() => reRoll(table, originalIndex)}>
              <td className="champion">
                <div className="champion-name">
                  {championImages[champion.id] ? (
                    <img
                      src={championImages[champion.id]}
                      alt={champion.name}
                    />
                  ) : (
                    <div className="loading-indicator">로딩 중...</div>
                  )}
                  {champion.name}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default React.memo(ChampionTable);
