import React, { useState, useEffect, useCallback, useRef } from "react";
import "./reset.css";
import "./App.css";
import Modal from "react-modal";
import ChampionTable from "./components/ChampionTable";
import BanModal from "./components/BanModal";
import OptionModal from "./components/OptionModal";
import html2canvas from "html2canvas";
import { shuffleArray } from "./utils/utils";
import ImageLoader from "./components/ImageLoader";
import { useChampionData } from "./hooks/useChampionData";
import ReactGA from "react-ga4";

Modal.setAppElement("#root");

const STORAGE_KEYS = {
  BANNED_CHAMPIONS: "lolApp_bannedChampions",
  TABLE_OPTIONS: "lolApp_tableOptions",
  SORT_OPTION: "lolApp_sortOption",
};

const DEFAULT_TABLE_OPTIONS = {
  rank: false,
  winrate: false,
  tier: true,
};

function App() {
  const { gameData, isLoading, error } = useChampionData();
  const captureRef = useRef();
  const loggedRef = useRef(false);

  const [randomChampions, setRandomChampions] = useState({
    table1: [],
    table2: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);
  const [bannedChampions, setBannedChampions] = useState(() =>
    JSON.parse(localStorage.getItem(STORAGE_KEYS.BANNED_CHAMPIONS) || "[]")
  );
  const [resetCount, setResetCount] = useState(0);
  const [displayCount, setDisplayCount] = useState(15);
  const [tableOptions, setTableOptions] = useState(() =>
    JSON.parse(
      localStorage.getItem(STORAGE_KEYS.TABLE_OPTIONS) ||
        JSON.stringify(DEFAULT_TABLE_OPTIONS)
    )
  );
  const [sortOption, setSortOption] = useState(
    () => localStorage.getItem(STORAGE_KEYS.SORT_OPTION) || "alphabetical"
  );
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (gameData.championData && !loggedRef.current) {
      console.log("gameData:", gameData);
      loggedRef.current = true;
    }
  }, [gameData]);

  const copyImageToClipboard = useCallback(async () => {
    if (displayCount !== 15) {
      alert(
        "이미지 복사는 챔피언 수가 15일 때만 가능합니다. 옵션에서 챔피언 수를 15로 설정해주세요."
      );
      return;
    }

    try {
      const canvas = await html2canvas(captureRef.current, {
        foreignObjectRendering: true,
        allowTaint: true,
        useCORS: true,
        scale: 1.1,
      });

      const captureWidth = 620;
      const captureHeight = 760;
      const startX = (canvas.width - captureWidth) / 2;
      const startY = (canvas.height - captureHeight) / 2 - 10;

      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = captureWidth;
      croppedCanvas.height = captureHeight;
      const ctx = croppedCanvas.getContext("2d");

      ctx.drawImage(
        canvas,
        startX,
        startY,
        captureWidth,
        captureHeight,
        0,
        0,
        captureWidth,
        captureHeight
      );

      const blob = await new Promise((resolve) =>
        croppedCanvas.toBlob(resolve, "image/png")
      );
      await navigator.clipboard.write([
        new ClipboardItem({ "image/png": blob }),
      ]);

      alert(
        "클립보드에 이미지가 복사되었습니다.\n붙여넣기(ctrl + v)로 사용하세요."
      );
    } catch (error) {
      console.error("이미지 복사 중 오류 발생:", error);
      alert("이미지 복사 중 오류가 발생했습니다.");
    }
  }, [displayCount]);

  const copyTextToClipboard = useCallback(() => {
    const blueTeam = randomChampions.table1
      .map((champ) => champ.name)
      .join(", ");
    const redTeam = randomChampions.table2
      .map((champ) => champ.name)
      .join(", ");

    const text = `블루 팀(${randomChampions.table1.length}): ${blueTeam}\n레드 팀(${randomChampions.table2.length}): ${redTeam}`;

    navigator.clipboard.writeText(text).then(
      () => {
        alert(
          "클립보드에 이미지가 복사되었습니다.\n붙여넣기(ctrl + v)로 사용하세요."
        );
      },
      (err) => {
        console.error("텍스트 복사 중 오류 발생:", err);
        alert("텍스트 복사 중 오류가 발생했습니다.");
      }
    );

    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Copy Text",
    });
  }, [randomChampions]);

  const handleToggleBan = useCallback((championId) => {
    setBannedChampions((prevBans) =>
      prevBans.includes(championId)
        ? prevBans.filter((id) => id !== championId)
        : [...prevBans, championId]
    );
  }, []);

  const resetRandomChampions = useCallback(() => {
    if (!gameData.championData) return;

    const champions = Object.values(gameData.championData.data).filter(
      (champion) => !bannedChampions.includes(champion.id)
    );
    const shuffled = shuffleArray(champions);
    setRandomChampions({
      table1: shuffled.slice(0, displayCount),
      table2: shuffled.slice(displayCount, displayCount * 2),
    });
    setResetCount((prevCount) => prevCount + 1);

    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Reset Champions",
    });
  }, [gameData.championData, bannedChampions, displayCount]);

  const handleReRollChampion = useCallback(
    (table, index) => {
      const selectedChampion = randomChampions[table][index].name;

      if (
        window.confirm(
          `"${selectedChampion}"을(를) 다시 랜덤하게 돌리시겠습니까?`
        )
      ) {
        const currentTableChampions = [
          ...randomChampions.table1,
          ...randomChampions.table2,
        ].map((champ) => champ.id);

        const availableChampions = Object.values(
          gameData.championData?.data || {}
        ).filter(
          (champion) =>
            !bannedChampions.includes(champion.id) &&
            !currentTableChampions.includes(champion.id)
        );

        if (availableChampions.length === 0) {
          alert("사용 가능한 챔피언이 없습니다.");
          return;
        }

        const randomChampion =
          availableChampions[
            Math.floor(Math.random() * availableChampions.length)
          ];

        setRandomChampions((prevChampions) => ({
          ...prevChampions,
          [table]: prevChampions[table].map((champ, idx) =>
            idx === index ? randomChampion : champ
          ),
        }));

        alert(
          `"${selectedChampion}"을(를) 돌려서 "${randomChampion.name}"이(가) 나왔습니다.`
        );
      }
    },
    [randomChampions, gameData.championData, bannedChampions]
  );

  useEffect(() => {
    localStorage.setItem(
      STORAGE_KEYS.TABLE_OPTIONS,
      JSON.stringify(tableOptions)
    );
    localStorage.setItem(STORAGE_KEYS.SORT_OPTION, sortOption);
  }, [tableOptions, sortOption]);

  useEffect(() => {
    localStorage.setItem(
      STORAGE_KEYS.BANNED_CHAMPIONS,
      JSON.stringify(bannedChampions)
    );
  }, [bannedChampions]);

  useEffect(() => {
    if (gameData.championData) {
      console.log("Champion data loaded, resetting champions");
      resetRandomChampions();
      setDataLoaded(true);
    }
  }, [gameData.championData, resetRandomChampions]);

  useEffect(() => {
    ReactGA.initialize("G-CRE7F98KB6");
    ReactGA.send("pageview");
  }, []);

  if (isLoading) return <div>로딩 중...</div>;
  if (error) return <div>에러 발생: {error.message}</div>;

  return (
    dataLoaded && (
      <div className="App">
        {
          <>
            <div className="button-container">
              <button
                className="ban-button"
                onClick={() => setIsModalOpen(true)}
              >
                챔피언 밴 ({bannedChampions.length})
              </button>
              <button
                className="option-button"
                onClick={() => setIsOptionModalOpen(true)}
              >
                옵션
              </button>
              <button className="copy-button" onClick={copyImageToClipboard}>
                이미지 복사
              </button>
              <button
                className="copy-text-button"
                onClick={copyTextToClipboard}
              >
                텍스트 복사
              </button>
            </div>

            <div className="container" ref={captureRef}>
              <ImageLoader
                champions={
                  gameData.championData
                    ? Object.values(gameData.championData.data)
                    : []
                }
                version={gameData.version}
              />
              <ChampionTable
                champions={randomChampions.table1}
                teamName="블루 팀"
                reRoll={handleReRollChampion}
                table="table1"
                version={gameData.version}
                tableOptions={tableOptions}
                sortOption={sortOption}
              />
              <button onClick={resetRandomChampions}>
                다시 뽑기
                <span>({resetCount})</span>
              </button>
              <ChampionTable
                champions={randomChampions.table2}
                teamName="레드 팀"
                reRoll={handleReRollChampion}
                table="table2"
                version={gameData.version}
                tableOptions={tableOptions}
                sortOption={sortOption}
              />
              <BanModal
                isOpen={isModalOpen}
                champions={gameData.championData}
                version={gameData.version}
                bannedChampions={bannedChampions}
                setBannedChampions={setBannedChampions}
                toggleBan={handleToggleBan}
                closeModal={() => setIsModalOpen(false)}
              />
              <OptionModal
                isOpen={isOptionModalOpen}
                displayCount={displayCount}
                setDisplayCount={setDisplayCount}
                closeModal={() => setIsOptionModalOpen(false)}
                tableOptions={tableOptions}
                setTableOptions={setTableOptions}
                sortOption={sortOption}
                setSortOption={setSortOption}
                maxDisplayCount={Math.floor(
                  (Object.keys(gameData.championData.data || {}).length -
                    bannedChampions.length) /
                    2
                )}
              />
            </div>

            <footer className="footer">
              패치 버전: {gameData.version.split(".").slice(0, 2).join(".")}
              <br />
              오류 및 문의사항: c99@kakao.com
            </footer>
          </>
        }
      </div>
    )
  );
}

export default App;
