import { SET_CHAMPION_IMAGES, SET_BANNED_CHAMPIONS } from "./actions";

const initialState = {
  championImages: {},
  bannedChampions: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAMPION_IMAGES:
      return {
        ...state,
        championImages: action.payload,
      };
    case SET_BANNED_CHAMPIONS:
      return {
        ...state,
        bannedChampions: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
