import { useState, useEffect } from "react";

export function useChampionData() {
  const [gameData, setGameData] = useState({
    championData: null,
    version: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(
          "https://ddragon.leagueoflegends.com/realms/kr.json"
        );
        const data1 = await response1.json();
        const championVersion = data1.n.champion;
        const response2 = await fetch(
          `https://ddragon.leagueoflegends.com/cdn/${championVersion}/data/ko_KR/champion.json`
        );
        const data2 = await response2.json();

        setGameData({
          championData: data2,
          version: championVersion,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching the data", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { gameData, isLoading, error };
}
