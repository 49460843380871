import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setChampionImages } from "../redux/actions"; // 이 액션을 만들어야 합니다

const toDataURL = (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
};

function ImageLoader({ champions, version }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const loadImages = async () => {
      const images = {};
      for (const champion of champions) {
        if (champion && champion.id) {
          // 유효성 검사 추가
          const imageUrl = `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.id}.png`;
          try {
            images[champion.id] = await toDataURL(imageUrl);
          } catch (error) {
            console.error(
              `Failed to load image for champion ${champion.id}:`,
              error
            );
          }
        }
      }
      dispatch(setChampionImages(images));
    };

    if (champions && champions.length > 0 && version) {
      loadImages();
    }
  }, [champions, version, dispatch]);

  return null; // 이 컴포넌트는 UI를 렌더링하지 않습니다
}

export default ImageLoader;
