import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

function BanModal({
  isOpen,
  champions,
  version,
  bannedChampions,
  toggleBan,
  closeModal,
  setBannedChampions,
}) {
  const championImages = useSelector((state) => state.championImages);

  const handleClearBans = () => {
    setBannedChampions([]);
    localStorage.removeItem("lolApp_bannedChampions");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-overlay"
      ariaHideApp={false}
    >
      <div className="modal-wrapper">
        <div className="modal-content">
          {champions &&
            Object.values(champions.data)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((champion) => {
                const isBanned = bannedChampions.includes(champion.id);
                return (
                  <div
                    key={champion.id}
                    className={`champion-item ${isBanned ? "banned" : ""}`}
                    onClick={() => toggleBan(champion.id)}
                  >
                    {championImages[champion.id] ? (
                      <img
                        src={championImages[champion.id]}
                        alt={champion.name}
                        className={isBanned ? "banned" : ""}
                      />
                    ) : (
                      <div className="loading-indicator">로딩 중...</div>
                    )}
                    <span className={isBanned ? "banned" : ""}>
                      {champion.name}
                    </span>
                  </div>
                );
              })}
        </div>
        <div className="option-modal-buttons">
          <button onClick={handleClearBans} className="clear-button">
            모두 해제 ({bannedChampions.length})
          </button>
          <button onClick={closeModal} className="close-button">
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BanModal;
