export const SET_CHAMPION_IMAGES = "SET_CHAMPION_IMAGES";

export const setChampionImages = (images) => ({
  type: SET_CHAMPION_IMAGES,
  payload: images,
});

export const SET_BANNED_CHAMPIONS = "SET_BANNED_CHAMPIONS";

export const setBannedChampions = (champions) => ({
  type: SET_BANNED_CHAMPIONS,
  payload: champions,
});
